import React from "react";
import { IconContext } from "react-icons";

import { MdArrowBack } from "react-icons/md";

const Card = ({ className, modifiers = "", header, image, imageAlt, title, sideText, previous, children }) => {
  return (
    <div
      className={`relative flex-1 flex flex-col bg-white dark:bg-neutral-750 rounded-lg shadow-lg text-justify ${className} ${
        modifiers.includes("secondary") && "!bg-neutral-150 dark:!bg-neutral-700 !shadow-md"
      } ${modifiers.includes("small") && "!flex-grow-0 !min-w-72"} ${modifiers.includes("medium") && "!flex-grow-0 !min-w-96"}`}
    >
      {header && (
        <div className={`bg-neutral-400 dark:bg-neutral-850 !bg-opacity-80 rounded-t-lg ${modifiers.includes("header-over") && "absolute w-full shadow-lg z-10"}`}>{header}</div>
      )}
      {image && (
        <img
          src={image}
          alt={imageAlt}
          className={`w-full ${!header || modifiers.includes("header-over") ? "rounded-t-lg" : ""} ${modifiers.includes("image-xs") && "h-32 object-cover"} ${
            modifiers.includes("image-sm") && "h-52 object-cover"
          } ${modifiers.includes("image-md") && "h-72 object-cover"} ${modifiers.includes("image-lg") && "h-96 object-cover"}`}
        />
      )}
      <div
        className={`flex flex-col w-full h-full p-6 ${modifiers.includes("no-overflow") && "overflow-y-hidden"} ${modifiers.includes("flex-1") && "flex-1"} ${
          modifiers.includes("padding-sm") && "!p-4"
        } ${modifiers.includes("padding-xs") && "!p-2"} ${modifiers.includes("padding-none") && "!p-0"}`}
      >
        {title || sideText || previous ? (
          <div className={`flex items-center mb-4 ${modifiers.includes("title-margin-sm") && "!mb-2"}`}>
            {previous && (
              <div
                className="mr-4 p-1.5 rounded-full cursor-pointer transition-all duration-200 hover:bg-neutral-250 dark:hover:bg-neutral-700"
                onClick={() => {
                  var currentUrl = window.location.href;
                  window.history.back();

                  setTimeout(function () {
                    if (currentUrl === window.location.href) {
                      window.location.assign(previous);
                    }
                  }, 100);
                }}
              >
                <IconContext.Provider value={{ className: "w-5 h-5" }}>
                  <MdArrowBack />
                </IconContext.Provider>
              </div>
            )}
            {title && (
              <h2
                className={`text-xl 3xl:text-2xl text-left font-semibold ${modifiers.includes("title-xs") ? "!text-sm 3xl:!text-base" : ""} ${
                  modifiers.includes("title-sm") ? "!text-base 3xl:!text-lg" : ""
                } ${modifiers.includes("title-lg") ? "!text-2xl 3xl:!text-3xl" : ""} ${modifiers.includes("title-xl") ? "!text-3xl 3xl:!text-4xl" : ""} ${
                  modifiers.includes("title-black") ? "!font-black" : ""
                }`}
              >
                {title}
              </h2>
            )}
            {sideText && <div className="ml-auto text-xs text-neutral-600 dark:text-neutral-400">{sideText}</div>}
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
};

export default Card;
