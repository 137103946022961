import React from "react";
import { useTranslation } from "react-i18next";

import useImagePreloader from "../../hooks/useImagePreloader";
import useDarkTheme from "../../hooks/useDarkTheme";

import HeaderLogo from "../../images/logo/logo_header-light_theme.png";
import HeaderLogoDark from "../../images/logo/logo_header-dark_theme.png";

const preloadSrcList = [HeaderLogo, HeaderLogoDark];

const TempMobileView = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  return (
    <div className="flex items-center justify-center bg-neutral-50 dark:bg-neutral-900 w-svw h-svh">
      <div className="flex flex-col items-center w-full md:w-3/4 min-h-1/2 p-6 sm:p-10 space-y-6">
        <div className="flex flex-col items-center justify-center">
          <img src={getActualTheme() === "dark" ? HeaderLogoDark : HeaderLogo} alt="Hoptop logo" className="w-full md:w-3/4 lg:w-1/2" />
          <p className="text-lg lg:text-xl text-center font-semibold mt-2">{t("mobileUnavailable")}</p>
        </div>
        <div className="flex items-center space-x-6">
          <div className="flex flex-col items-center justify-end w-full space-y-2">
            <p className="text-center">{t("accessOnComputer")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TempMobileView;
