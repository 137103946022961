/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { APIProvider } from "@vis.gl/react-google-maps";

import AutocompleteInput from "./components/AutocompleteInput";
import Input from "../forms/inputs/Input";
import ShowMap from "./ShowMap";

const FormMap = ({ id, type = "marker", formData, setFormData, hasHome, setShowingMap, modifiers = "" }) => {
  const { t } = useTranslation();

  const [coords, setCoords] = useState();
  const [address, setAddress] = useState(formData?.addr ? formData?.addr : formData[id]);
  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    if (formData?.addr && formData.addr !== address) {
      setFormData({ ...formData, addr: address });
    }

    if (!formData?.addr && formData[id] !== address) {
      setFormData({ ...formData, [id]: address });
    }
  }, [address]);

  useEffect(() => {
    setAddress(formData?.addr ? formData?.addr : formData[id]);
  }, [formData]);

  useEffect(() => {
    if (setShowingMap) {
      setShowingMap(true);

      return () => {
        setShowingMap(false);
      };
    }
  }, []);

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <div className="space-y-4">
        <div className="flex space-x-4">
          <div className="flex-1 flex flex-col justify-between space-y-4">
            <AutocompleteInput setCoords={setCoords} address={address} setAddress={setAddress} isHome={isHome} setIsHome={setIsHome} hasHome={hasHome} />

            {modifiers.includes("small") && (
              <>
                <Input formData={address} setFormData={setAddress} id="address" label={t("address.address")} required disabled />
                <Input formData={address} setFormData={setAddress} id="apt" label={t("address.apartment")} disabled={isHome} />
                <Input formData={address} setFormData={setAddress} id="city" label={t("address.city")} required disabled />
                <Input formData={address} setFormData={setAddress} id="province" label={t("address.province")} required disabled />
                <Input formData={address} setFormData={setAddress} id="country" label={t("address.country")} required disabled />
                <Input formData={address} setFormData={setAddress} id="postalCode" label={t("address.postalCode")} required disabled />
                {modifiers.includes("phone") && <Input type="phone" formData={formData} setFormData={setFormData} id="phone" label={t("phone")} required />}
              </>
            )}

            {!modifiers.includes("small") && (
              <>
                <div className="flex space-x-4">
                  <Input formData={address} setFormData={setAddress} id="address" label={t("address.address")} required disabled />
                  <Input formData={address} setFormData={setAddress} id="apt" label={t("address.apartment")} disabled={isHome} />
                </div>
                <div className="flex space-x-4">
                  <Input formData={address} setFormData={setAddress} id="city" label={t("address.city")} required disabled />
                  <Input formData={address} setFormData={setAddress} id="postalCode" label={t("address.postalCode")} required disabled />
                </div>
                <div className="flex space-x-4">
                  <Input formData={address} setFormData={setAddress} id="province" label={t("address.province")} required disabled />
                  <Input formData={address} setFormData={setAddress} id="country" label={t("address.country")} required disabled />
                </div>
                {modifiers.includes("phone") && <Input type="phone" formData={formData} setFormData={setFormData} id="phone" label={t("phone")} required />}
              </>
            )}
          </div>

          <ShowMap type={type} coords={coords} modifiers={modifiers} />
        </div>
        {modifiers.includes("disclaimer") && (
          <div className="">
            <p className="text-sm">{t("mapDisclaimer")}</p>
          </div>
        )}
      </div>
    </APIProvider>
  );
};

export default FormMap;
