import React from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { HiOutlineDocumentSearch, HiOutlineLightningBolt, HiOutlineScale } from "react-icons/hi";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ServicesFast = () => {
  const { t } = useTranslation();

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const cards = [
    {
      icon: <HiOutlineLightningBolt />,
      title: t("tilesTitle1"),
      text: t("tilesText1")
    },
    {
      icon: <HiOutlineDocumentSearch />,
      title: t("tilesTitle2"),
      text: t("tilesText2")
    },
    {
      icon: <HiOutlineScale />,
      title: t("tilesTitle3"),
      text: t("tilesText3")
    }
  ];

  const [ref1, inView1] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: false, threshold: 0.1 });

  const refs = [ref1, ref2, ref3];
  const inViews = [inView1, inView2, inView3];

  return (
    <section className="text-black dark:text-white">
      <div className="mx-auto max-w-screen-2xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold sm:text-4xl">{t("tilesTitle")}</h2>
          <p className="mt-4 text-gray-800 dark:text-gray-300">{t("tilesDescription")}</p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {cards.map((item, index) => (
            <motion.div
              ref={refs[index]}
              key={index}
              className="block rounded-xl p-8 shadow-md transition bg-[#DCDCDC] dark:bg-neutral-800 hover:shadow-xl"
              initial="hidden"
              animate={inViews[index] ? "visible" : "hidden"}
              variants={cardVariants}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <IconContext.Provider value={{ className: "w-10 h-10 text-hoptop-500" }}>{item.icon}</IconContext.Provider>
              <h2 className="mt-4 text-xl font-bold text-black dark:text-white">{item.title}</h2>
              <p className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                <br />
                {item.text}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesFast;
