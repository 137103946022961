import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useDarkTheme from "../../hooks/useDarkTheme";

import Card from "../card/Card";
import ConversationHeader from "./elements/ConversationHeader";
import MessageInput from "./elements/MessageInput";
import Message from "./elements/Message";

const Conversation = ({ conversation, fetchMoreMessages }) => {
  ring2.register();

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onScroll = (event) => {
    if (event.target.scrollTop * -1 >= event.target.scrollHeight - event.target.offsetHeight - 1 && !isLoading) {
      setIsLoading(true);
      fetchMoreMessages(conversation._id, setIsLoading);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (conversation?.recipient?.typing !== undefined) {
      setIsTyping(conversation.recipient.typing);
    }
  }, [conversation?.recipient?.typing]);

  let lastInBunch = false;

  return (
    <Card modifiers="header-over" className="!flex-9/12 3xl:!flex-4/5 w-px h-[calc(100svh-3rem)]" header={<ConversationHeader conversation={conversation} />}>
      <div className={`flex flex-col h-full space-y-4 ${"TODO" === "No conversations" && "justify-center items-center"}`}>
        {conversation ? (
          <>
            <div onScroll={onScroll} className="flex-1 flex flex-col-reverse w-full h-full overflow-y-scroll px-2 pt-28 space-y-3 space-y-reverse scrollbar-preset">
              {isTyping && <Message loading lastInBunch />}
              {conversation.messages.map((message, i) => {
                const nextMessage = conversation.messages[i - 1];

                lastInBunch = false;

                if (!nextMessage) {
                  lastInBunch = true;
                } else {
                  if (message.author !== nextMessage.author) {
                    lastInBunch = true;
                  }
                }

                return <Message key={i} conversationId={conversation._id} message={message} lastInBunch={lastInBunch} />;
              })}
              {conversation.lastMessagesIndexLoaded !== -1 && (
                <div className={`flex items-center justify-center pt-4 pb-8 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
                  <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
                </div>
              )}
            </div>
            <MessageInput conversation={conversation} />
          </>
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-center">{t("selectConversation")}</p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Conversation;
