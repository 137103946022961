import React from "react";
import Avatar from "../../avatar/Avatar";

const AvatarInfoBox = ({ avatar, name, modifiers = "" }) => {
  return (
    <div className={`flex items-center space-x-2 ${modifiers.includes("space-lg") ? "space-x-4" : ""}`}>
      <div
        className={`w-6 h-6 ${modifiers.includes("avatar-xs") ? "!w-5 !h-5" : ""} ${modifiers.includes("avatar-sm") ? "!w-8 !h-8" : ""} ${
          modifiers.includes("avatar-xl") ? "!w-16 !h-16" : ""
        } ${modifiers.includes("small-home") ? "!w-5 3xl:!w-6 !h-5 3xl:!h-6" : ""}`}
      >
        <Avatar image={avatar} alt={"Profile picture for " + name} />
      </div>
      <p
        className={`${modifiers.includes("text-xs") ? "text-xs" : ""} ${modifiers.includes("text-sm") ? "text-sm" : ""} ${modifiers.includes("text-2xl") ? "text-2xl" : ""} ${
          !modifiers.includes("text-not-bold") ? "font-semibold" : ""
        } ${modifiers.includes("small-home") ? "text-sm 3xl:text-base" : ""}`}
      >
        {name}
      </p>
    </div>
  );
};

export default AvatarInfoBox;
