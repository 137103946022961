/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { motion } from "framer-motion";

import useGeneral from "../../../hooks/useGeneral";
import useAuth from "../../../hooks/useAuth";
import useTenders from "../../../hooks/useTenders";
import useLogger from "../../../hooks/useLogger";

import { MdChevronLeft, MdChevronRight, MdKeyboardArrowDown } from "react-icons/md";

import Card from "../../../components/card/Card";
import Button from "../../../components/button/Button";
import Separator from "../../../components/Separator";
import TenderInfoBox from "../../../components/card/dashboard/TenderInfoBox";
import TenderQuestionsAccordion from "../../../components/accordion/TenderQuestionsAccordion";
import ServiceOffersDisplay from "../../../components/serviceOffers/ServiceOffersDisplay";
import AvatarInfoBox from "../../../components/card/dashboard/AvatarInfoBox";
import RegularMap from "../../../components/map/RegularMap";
import UrgentTenderIcon from "../../../components/card/dashboard/UrgentTenderIcon";

const DashboardTender = () => {
  const { tenderId } = useParams();

  const { t } = useTranslation();

  const tenderRef = useRef(null);
  const descriptionRef = useRef(null);

  const { formatDate } = useGeneral();
  const { isCompany, isAdmin } = useAuth();
  const { getStatusColor } = useTenders();
  const { showResponse, confirmAction } = useLogger();

  const [validId, setValidId] = useState(true);
  const [tender, setTender] = useState();
  const [serviceOffers, setServiceOffers] = useState([]);
  const [myServiceOffer, setMyServiceOffer] = useState(null);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [descriptionIsOverflowing, setDescriptionIsOverflowing] = useState(false);
  const [serviceOffersExpanded, setServiceOffersExpanded] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const getTender = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `tenders/${tenderId}`, { withCredentials: true })
      .then((response) => {
        setTender(response.data);
      })
      .catch((e) => {
        setValidId(false);

        showResponse(e);
        setTimeout(() => {
          if (isCompany()) {
            window.location.assign("/dashboard/tenders/browse");
          } else {
            window.location.assign("/dashboard/tenders");
          }
        }, 2000);
      });
  };

  const setDescriptionOverflow = () => {
    if (!descriptionExpanded) {
      setDescriptionIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
    }
  };

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    // FIXME Along with BigServiceOffersList.jsx, ServiceOffersDisplay.jsx, SettingContent.jsx, TendersList.jsx, DashboardHome.jsx & DashboardMessages.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(tenderRef.current.scrollTop);
    setScrollIsBottom(Math.abs(tenderRef.current.scrollHeight - tenderRef.current.scrollTop - tenderRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    tenderRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      tenderRef.current.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  useEffect(() => {
    getTender();
  }, []);

  useEffect(() => {
    if (descriptionRef.current) {
      setDescriptionOverflow();
    }
  });

  return (
    <div className="flex space-x-6">
      <div
        ref={tenderRef}
        className={`!flex-4/6 h-[calc(100svh-3rem)] mr-2 pr-2 overflow-y-scroll scrollbar-preset-thin ${
          !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
        }`}
      >
        <Card
          title={
            !validId ? (
              t("backend.error.tenderInvalid")
            ) : !tender ? (
              t("loading")
            ) : (
              <div className="flex space-x-4 whitespace-nowrap">
                <p className="max-w-md 3xl:max-w-3xl text-ellipsis overflow-hidden">{tender.title}</p>
                {tender.urgent ? <UrgentTenderIcon /> : undefined}
              </div>
            )
          }
          sideText={tender && t("createdDate", { date: formatDate(tender.createdAt) })}
          image={tender?.images[0]}
          modifiers="image-sm"
          previous={tender?.loggedUserIsAuthor ? "/dashboard/tenders" : !isAdmin() ? "/dashboard/tenders/browse" : "/dashboard/admin"}
        >
          {validId && tender ? (
            <div className="flex flex-col h-full space-y-4">
              <div className="flex items-center justify-between">
                <AvatarInfoBox avatar={null} name={tender.author} />
                <div className="flex items-center space-x-2">
                  <TenderInfoBox type="status" info={t("backend.enums.tenderStatuses." + tender.status)} color={getStatusColor(tender.status)} />
                </div>
              </div>
              <div className="flex items-center justify-between text-sm">
                <p>{`${t("tenderDomains." + tender.domain)} > ${t("tenderSubdomains." + tender.subdomain)} > ${t("tenderServices." + tender.service)}`}</p>
                <p>{t("deliveryByDate", { date: formatDate(tender.deliverBy) })}</p>
              </div>
              <Separator />
              {/* TODO Add an animation at some point (it's broken) */}
              <div className={`${descriptionExpanded ? "h-fit" : "h-[4.5rem]"}`}>
                <p ref={descriptionRef} className={`${descriptionExpanded ? "max-h-fit" : "max-h-12"} whitespace-break-spaces break-words overflow-hidden`}>
                  {tender.description}
                </p>
                {descriptionIsOverflowing && (
                  <div
                    className="flex font-semibold text-hoptop-500 hover:text-hoptop-600 border-b-2 border-transparent hover:border-hoptop-600 w-fit cursor-pointer"
                    onClick={() => {
                      setDescriptionExpanded(!descriptionExpanded);
                    }}
                  >
                    <p>{descriptionExpanded ? t("viewLess") : t("viewMore")}</p>
                    <IconContext.Provider value={{ className: "w-full h-full" }}>
                      <motion.div animate={{ rotate: descriptionExpanded ? "180deg" : "0deg" }}>
                        <MdKeyboardArrowDown />
                      </motion.div>
                    </IconContext.Provider>
                  </div>
                )}
              </div>
              <TenderQuestionsAccordion tender={tender} questions={tender.questions} defaultValue={true} />
              <div className="flex space-x-6">
                <div className="flex-1 flex flex-col space-y-2">
                  <RegularMap
                    type={tender.loggedUserIsAuthor || isAdmin() ? "marker" : "radius"}
                    address={tender.loggedUserIsAuthor && !isAdmin() ? tender.address : undefined}
                    coords={
                      !tender.loggedUserIsAuthor || isAdmin()
                        ? {
                            lat: tender.latitude,
                            lng: tender.longitude
                          }
                        : undefined
                    }
                  />
                  {tender.loggedUserIsAuthor && <p className="text-sm">{t("mapDisclaimer")}</p>}
                </div>
                {/* <div className="flex-1 rounded-xl bg-blue-500">TODO Add the photo slideshow</div> */}
              </div>
              <div className="flex items-center justify-end space-x-2">
                {tender.loggedUserIsAuthor && (
                  <>
                    {tender.status === "open" && serviceOffers.length === 0 && <Button modifiers="width-xs" label={t("edit")} link={`/dashboard/tenders/${tender._id}/edit`} />}
                    {(tender.status === "open" || tender.status === "ongoing") && (
                      <Button
                        modifiers="width-xs"
                        label={t(tender.status === "open" ? "close" : "cancel")}
                        action={() => {
                          confirmAction(t(tender.status === "open" ? "confirmCloseTender" : "confirmCancelTender"), async () => {
                            await axios
                              .patch(process.env.REACT_APP_BACKEND_URL + `tenders/${tenderId}/close`, {}, { withCredentials: true })
                              .then((response) => {
                                showResponse(response);
                                setTimeout(() => {
                                  window.location.assign("/dashboard/tenders");
                                }, 2000);
                              })
                              .catch((e) => {
                                showResponse(e);
                              });
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ) : null}
        </Card>
      </div>
      <div className="relative flex space-x-6">
        <Separator direction="vertical" className="border-neutral-300 dark:border-neutral-600" />
        {tender?.loggedUserIsAuthor && (
          <div
            onClick={() => {
              setServiceOffersExpanded(!serviceOffersExpanded);
            }}
            className="absolute flex items-center justify-center top-1/2 left-0 -translate-y-1/2 -translate-x-[125%] bg-neutral-500 dark:bg-neutral-750 w-8 h-8 p-1 rounded-full shadow-md cursor-pointer"
          >
            <IconContext.Provider value={{ className: "w-full h-full text-white" }}>{serviceOffersExpanded ? <MdChevronRight /> : <MdChevronLeft />}</IconContext.Provider>
          </div>
        )}
        {!isAdmin() || tender?.loggedUserIsAuthor ? (
          <ServiceOffersDisplay
            tender={tender}
            serviceOffers={serviceOffers}
            setServiceOffers={setServiceOffers}
            myServiceOffer={myServiceOffer}
            setMyServiceOffer={setMyServiceOffer}
            serviceOffersExpanded={serviceOffersExpanded}
          />
        ) : (
          <div className={`flex flex-col flex-2/6 w-[27rem] h-[calc(100svh-3rem)]`}>
            <Card modifiers="small">
              <div>
                <p>TODO Add admin card</p>
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardTender;
