/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import useGeneral from "../../hooks/useGeneral";
import useServiceOffers from "../../hooks/useServiceOffers";
import useLogger from "../../hooks/useLogger";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

import Card from "../card/Card";
import TenderInfoBox from "../card/dashboard/TenderInfoBox";
import DateInfoBox from "../card/dashboard/DateInfoBox";
import Button from "../button/Button";
import AvatarInfoBox from "../card/dashboard/AvatarInfoBox";
import Separator from "../Separator";
import Popup from "../popup/Popup";
import { IconContext } from "react-icons";
import { MdOutlineQuestionAnswer } from "react-icons/md";

const MyServiceOffer = ({ tender, offer, setMyServiceOffer }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getStatusColor, showPrice } = useServiceOffers();
  const { showResponse, confirmAction } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [conversationExists, setConversationExists] = useState(false);
  const [messageButtonIsHover, setMessageButtonIsHover] = useState(false);

  const deleteServiceOffer = async () => {
    confirmAction(t("confirmDeleteOffer"), async () => {
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + `service-offers/${offer.id}`, { withCredentials: true })
        .then((response) => {
          showResponse(response);

          setMyServiceOffer(null);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const startConversation = async () => {
    confirmAction(t("confirmStartConversation", { name: tender.author }), async () => {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `conversations/create/${offer.companyId}/${tender.userId}`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          setTimeout(() => {
            window.location.assign("/dashboard/messages");
          }, 2000);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const checkIfConversationExists = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/check/${offer.companyId}/${tender.userId}`, { withCredentials: true })
      .then((response) => {
        setConversationExists(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (offer) {
      checkIfConversationExists();
    }
  }, [offer]);

  return (
    <Card title={t("myServiceOffer")} modifiers="small">
      <div className="space-y-3">
        <AvatarInfoBox avatar={selectedCompany.logo} name={selectedCompany.name} />
        <div className="flex justify-between text-sm">
          <p>{t("submitted", { date: formatDate(offer.createdAt) })}</p>
          <TenderInfoBox type="status" info={t("backend.enums.serviceOfferStatuses." + offer.status)} color={getStatusColor(offer.status)} />
        </div>
        <Separator />
        <div className="space-y-3">
          <p className="whitespace-break-spaces break-words">{offer.message}</p>
          <div className="space-y-1">
            <p>{showPrice(offer)}</p>
            <div className="flex">
              <DateInfoBox start={offer.date.start} end={offer.date.end} />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-2">
          <Button
            label={t("delete")}
            action={() => {
              deleteServiceOffer();
            }}
            modifiers="width-3xs height-2xs no-margin"
          />
          {(offer.status === "pending" || offer.status === "approved") && (tender.status === "open" || tender.status === "ongoing") && (
            <div
              className="relative w-full max-w-5xs"
              onMouseEnter={() => {
                setMessageButtonIsHover(true);
              }}
              onMouseLeave={() => {
                setMessageButtonIsHover(false);
              }}
            >
              <Button
                label={
                  <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                    <MdOutlineQuestionAnswer />
                  </IconContext.Provider>
                }
                action={!conversationExists ? startConversation : undefined}
                link={conversationExists ? "/dashboard/messages" : undefined}
                modifiers="height-2xs no-margin"
                background={conversationExists ? "bg-gray-500 dark:bg-gray-600 hover:bg-gray-400 hover:dark:bg-gray-500" : undefined}
              />
              <Popup position="top" width="w-max" height="h-8" modifiers="end" shown={messageButtonIsHover}>
                <p className="relative p-0.5 z-10 text-sm">{conversationExists ? t("goToConversations") : t("startConversation")}</p>
              </Popup>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default MyServiceOffer;
