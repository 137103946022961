/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import useLogger from "../../../hooks/useLogger";

import Card from "../../../components/card/Card";
import PendingCompany from "../../../components/admin/PendingCompany";
import SelectedPendingCompany from "../../../components/admin/SelectedPendingCompany";

const DashboardAdmin = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const getDashboardAdmin = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "companies/pending", { withCredentials: true })
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const verifyCompany = async (companyId) => {
    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `companies/${companyId}/verify`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setSelectedCompany(null);
        setCompanies(
          companies.filter((company) => {
            if (company._id !== companyId) {
              return company;
            }

            return null;
          })
        );
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const denyCompany = async (companyId) => {
    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `companies/${companyId}/deny`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setSelectedCompany(null);
        setCompanies(
          companies.filter((company) => {
            if (company._id !== companyId) {
              return company;
            }

            return null;
          })
        );
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const selectCompany = (index) => {
    setSelectedCompany(companies[index]);
  };

  useEffect(() => {
    getDashboardAdmin();
  }, []);

  return (
    <div className="flex h-[calc(100svh-3rem)] space-x-4">
      <div className="flex flex-1/4">
        <Card title={t("dashboardAdmin")}>
          {companies.length > 0 ? (
            <div className="space-y-3">
              {companies.map((company, i) => {
                return <PendingCompany key={i} company={company} selectCompany={selectCompany} index={i} />;
              })}
            </div>
          ) : (
            <div className="flex-1 flex items-center justify-center">
              <p>{t("noCompaniesToVerify")}</p>
            </div>
          )}
        </Card>
      </div>
      <div className="flex flex-3/4">
        <Card title={t("companyInfo")}>
          <SelectedPendingCompany company={selectedCompany} verifyCompany={verifyCompany} denyCompany={denyCompany} />
        </Card>
      </div>
    </div>
  );
};

export default DashboardAdmin;
