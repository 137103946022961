import React, { useRef, useState } from "react";
import axios from "axios";
import i18n from "../../common/language/i18n";
import { Trans, useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";
import useLogger from "../../hooks/useLogger";

import Logo from "../../images/logo/logo-light_theme.svg";
import LogoDark from "../../images/logo/logo-dark_theme.svg";

import Button from "../../components/button/Button";
import Card from "../../components/card/Card";
import Form from "../../components/forms/Form";
import MultiStepForm from "../../components/forms/multistep/MultiStepForm";

const preloadSrcList = [Logo];

const Auth = ({ mode }) => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { signUp, verifyTwoFactorCode } = useAuth();
  const { getActualTheme } = useDarkTheme();
  const { showResponse } = useLogger();

  // MFA form dependencies
  const [userId, setUserId] = useState();
  const [code, setCode] = useState(Array(6).fill(""));
  const [error, setError] = useState("");
  const [isCodeMode, setIsCodeMode] = useState(false);
  const inputRefs = useRef([]);

  const LOGIN_MODE = t("login"),
    SIGNUP_MODE = t("signup");
  const SIGNUP_NB_PAGES = 3;

  const recaptcha = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    addr: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      isHome: false
    },
    phone: "",
    recaptcha: "",
    preferredLanguage: "",
    userId: "",
    code: "",
    rememberMe: false,
    notAMinor: false,
    termsOfUse: false
  });

  const [showingMap, setShowingMap] = useState(false);

  const loginContent = [
    { type: "email", id: "email", label: t("email"), required: true },
    { type: "password", id: "password", label: t("password"), required: true },
    { type: "checkbox", id: "rememberMe", name: "rememberMe", label: t("rememberMe") }
  ];

  const signupContent = [
    {
      content: [
        { type: "text", id: "firstName", label: t("firstName"), required: true },
        { type: "text", id: "lastName", label: t("lastName"), required: true },
        { type: "email", id: "email", label: t("email"), required: true },
        { type: "password", id: "password", label: t("password"), required: true, modifiers: "passwordRequirements" }
      ]
    },
    {
      content: [{ type: "address", mapType: "marker", hasHome: false, modifiers: "small phone", setShowingMap: setShowingMap }]
    },
    {
      content: [
        {
          type: "inputgroup",
          name: "preferredLanguage",
          label: t("preferredLanguage"),
          direction: "horizontal",
          inputs: [
            { type: "radio", from: "preferredLanguage", id: "en", name: "en", label: t("en") },
            { type: "radio", from: "preferredLanguage", id: "fr", name: "fr", label: t("fr") }
          ]
        },
        { type: "checkbox", id: "notAMinor", name: "notAMinor", label: t("notAMinor") },
        {
          type: "checkbox",
          id: "termsOfUse",
          name: "termsOfUse",
          label: (
            <p>
              <Trans i18nKey="termsOfUse">
                I have read and accept Hoptop's{" "}
                <a href="/policy" className="text-hoptop-500 cursor-pointer hover:underline">
                  terms of use
                </a>
                .
              </Trans>
            </p>
          )
        },
        { type: "recaptcha", recaptcha: recaptcha }
      ]
    }
  ];

  const content =
    mode === LOGIN_MODE ? (
      <Form
        type="login"
        content={loginContent}
        formData={formData}
        setFormData={setFormData}
        action={(event) => {
          login(event, formData);
        }}
      />
    ) : mode === SIGNUP_MODE ? (
      <MultiStepForm
        type="sign-up"
        nbPages={SIGNUP_NB_PAGES}
        content={signupContent}
        formData={formData}
        setFormData={setFormData}
        action={(event) => {
          signUp(event, formData, recaptcha);
        }}
      />
    ) : null;

  const login = async (event, formData) => {
    event.preventDefault();

    const newFormData = JSON.parse(JSON.stringify(formData));
    delete newFormData.addr.isHome;

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "users/login", newFormData, {
        withCredentials: true
      })
      .then(async (response) => {
        // 2FA
        if (response.status === 200 && response.data.message === "confirm2FA") {
          setUserId(response.data.userId);
          await sendTwoFactorCode(response.data.userId);
        }

        // No 2FA
        else {
          i18n.changeLanguage(response.data.lang);
          localStorage.removeItem("tempEmail");
          showResponse(response);

          setTimeout(() => {
            window.location.assign("/dashboard");
          }, 2000);
        }
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const sendTwoFactorCode = async (userId) => {
    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "users/send-phone",
        { userId },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        setIsCodeMode(true);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  // MFA input box dependencies
  const handleChange = (event, index) => {
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);

    if (event.target.value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleFocus = (event) => {
    setError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const verificationCode = code.join("");
    if (verificationCode.length === 6) {
      verifyTwoFactorCode(event, userId, formData.password, verificationCode, formData.rememberMe);
    } else {
      setError(t("invalidCode"));
    }
  };

  // MFA input box
  const showCodeInputBox = (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <p>{t("verificationMessage")}</p>
      <div className="flex flex-col items-center">
        <div className="flex">
          {code.map((value, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              className="w-12 h-12 text-center mx-1 bg-transparent border border-gray-300 rounded-md"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onKeyPress={handleKeyPress}
              onFocus={handleFocus}
              ref={(el) => (inputRefs.current[index] = el)}
              autoFocus={index === 0}
            />
          ))}
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
      <div className="flex justify-end space-x-2">
        <Button modifiers="width-xs" label={t("verify")} />
      </div>
    </form>
  );

  return (
    <div className="flex flex-col items-center text-gray-600 dark:text-gray-50 w-full h-screen p-4">
      <div className="absolute w-32 h-32">
        <a href="/" className="w-full h-full">
          <img src={getActualTheme() === "dark" ? LogoDark : Logo} alt="logo" className="w-full h-full p-4" />
        </a>
      </div>
      <div className={`flex flex-col justify-center sm:w-full ${showingMap ? "md:w-3/4" : "md:w-2/5"} h-full mt-32 py-4`}>
        <Card title={mode} modifiers="small">
          <div className="space-y-4">
            {!isCodeMode ? content : showCodeInputBox}
            {!isCodeMode && (
              <div className={`flex items-center ${mode === LOGIN_MODE ? "justify-between" : mode === SIGNUP_MODE ? "justify-center" : ""} text-xs 3xl:text-sm`}>
                {mode === LOGIN_MODE && (
                  <>
                    <p>
                      <a href="/forgot-password" className="text-hoptop-500 hover:underline">
                        {t("forgotPassword")}
                      </a>
                    </p>
                    <p>
                      {t("newToHoptop")}{" "}
                      <a href="/sign-up" className="text-hoptop-500 hover:underline">
                        {t("signup")}
                      </a>
                    </p>
                  </>
                )}
                {mode === SIGNUP_MODE && (
                  <p>
                    {t("alreadyHaveAccount")}{" "}
                    <a href="/login" className="text-hoptop-500 hover:underline">
                      {t("login")}
                    </a>
                  </p>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Auth;
