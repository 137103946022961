import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";

import domains from "../../../data/json/domains.json";

import Card from "../../../components/card/Card";
import MultiStepForm from "../../../components/forms/multistep/MultiStepForm";
import CreateTenderReviewPage from "../../../components/card/createTenderReview/CreateTenderReviewPage";

const DashboardCreateTenders = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const NB_PAGES = 5;

  const [tenderData, setTenderData] = useState({
    title: "",
    description: "",
    addr: {
      address: "",
      apt: "",
      city: "",
      province: "",
      country: "",
      postalCode: "",
      isHome: false
    },
    deliverBy: "",
    urgent: false,
    domain: "",
    subdomain: "",
    service: "",
    questions: []
  });

  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [questions, setQuestions] = useState([]);

  const onChangeDomain = (index) => {
    if (index !== undefined) {
      setSelectedDomain(domains[index]);
      setSelectedSubdomain(null);
      setSelectedService(null);
      setQuestions(null);

      setTenderData({
        ...tenderData,
        domain: domains[index].name,
        subdomain: "",
        service: "",
        questions: []
      });
    } else {
      setSelectedDomain(null);
      setSelectedSubdomain(null);
      setSelectedService(null);
      setQuestions(null);

      setTenderData({
        ...tenderData,
        domain: "",
        subdomain: "",
        service: "",
        questions: []
      });
    }
  };

  const onChangeSubdomain = (index) => {
    if (index !== undefined) {
      setSelectedSubdomain(selectedDomain?.subdomains[index]);
      setSelectedService(null);
      setQuestions(null);

      setTenderData({
        ...tenderData,
        subdomain: selectedDomain?.subdomains[index].name,
        service: "",
        questions: []
      });
    } else {
      setSelectedSubdomain(null);
      setSelectedService(null);
      setQuestions(null);

      setTenderData({
        ...tenderData,
        subdomain: "",
        service: "",
        questions: []
      });
    }
  };

  const onChangeService = (index) => {
    if (index !== undefined) {
      setSelectedService(selectedSubdomain?.services[index]);
      setQuestions(selectedSubdomain?.services[index].questions);

      setTenderData({
        ...tenderData,
        service: selectedSubdomain?.services[index].name,
        questions: getQuestionsForTenderData(selectedSubdomain?.services[index])
      });
    } else {
      setSelectedService(null);
      setQuestions(null);

      setTenderData({
        ...tenderData,
        service: "",
        questions: []
      });
    }
  };

  const getQuestionsForTenderData = (service) => {
    if (service) {
      let questions = [];

      service.questions.forEach((question) => {
        questions.push({ q: "q" + (question.index + 1), a: "" });
      });

      return questions;
    }

    return [];
  };

  const tenderContent = [
    {
      title: t("createTenderPage2"),
      content: [
        {
          type: "dropdown",
          id: "domain",
          name: "domain",
          label: t("selectDomain"),
          onChange: onChangeDomain,
          required: true,
          items: domains
        },
        selectedDomain
          ? {
              type: "dropdown",
              id: "subdomain",
              name: "subdomain",
              label: t("selectSubdomain"),
              onChange: onChangeSubdomain,
              required: true,
              items: selectedDomain?.subdomains
            }
          : null,
        selectedSubdomain
          ? {
              type: "dropdown",
              id: "service",
              name: "service",
              label: t("selectService"),
              onChange: onChangeService,
              required: true,
              items: selectedSubdomain?.services
            }
          : null
      ]
    },
    {
      title: t("createTenderPage4"),
      content: [
        { type: "text", id: "title", label: t("title"), required: true },
        { type: "textarea", id: "description", label: t("description"), required: true },
        { type: "date", id: "deliverBy", label: t("deliverBy"), required: true },
        { type: "checkbox", id: "urgent", name: "urgent", label: t("isUrgent"), required: false }
      ]
    },

    { title: t("createTenderPage3"), content: selectedService !== null ? questions : [] },
    {
      title: t("createTenderPage1"),
      content: [{ type: "address", mapType: "marker", hasHome: true, modifiers: "disclaimer" }]
    },
    {
      title: t("createTenderPage5"),
      content: <CreateTenderReviewPage data={tenderData} />
    }
  ];

  const createTender = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const newTenderData = JSON.parse(JSON.stringify(tenderData));
    delete newTenderData.addr.isHome;
    if (newTenderData.addr.apt === "") {
      delete newTenderData.addr.apt;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "tenders/create-tender", newTenderData, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign(`/dashboard/tenders/${response.data.id}`);
        }, 2000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return (
    <div>
      <Card title={t("dashboardCreateTender")}>
        <MultiStepForm nbPages={NB_PAGES} content={tenderContent} formData={tenderData} setFormData={setTenderData} action={createTender} buttonLabel={t("createTender")} />
      </Card>
    </div>
  );
};

export default DashboardCreateTenders;
