import React from "react";

import HomeStart from "./HomeStart";
import Services from "./ServicesFast";
import Fonctionnalite from "./Fonctionnalite";
import Faq from "./Faq";
//import Blogue from "./Blogue";
// import Team from "./Team";

const Home = () => {
  return (
    <div>
      <HomeStart page="client" />
      <div className="bg-neutral-100 dark:bg-[#2B2B2B]">
        <section id="services">
          <Services />
        </section>
        <section id="resources">
          <Fonctionnalite />
        </section>
        {/*
        <section id="blogue">
          <Blogue />
        </section>
        */}
        {/*<section id="team">
          <Team />
        </section>*/}
        <section id="faq">
          <Faq />
        </section>
      </div>
    </div>
  );
};

export default Home;
