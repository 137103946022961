/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useDarkTheme from "../../hooks/useDarkTheme";

import BigServiceOfferCard from "../card/dashboard/BigServiceOfferCard";

const BigServiceOffersList = ({ offers, getMoreOffers, isLastBatch }) => {
  ring2.register();

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  const serviceOfferListRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [endMessageShown, setEndMessageShown] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const onScroll = (event) => {
    if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
      if (!isLoading) {
        setIsLoading(true);
        getMoreOffers(setIsLoading);
      }

      if (isLastBatch) {
        setEndMessageShown(true);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    // FIXME Along with ServiceOffersDisplay.jsx, SettingContent.jsx, TendersList.jsx, DashboardHome.jsx, DashboardMessages.jsx & DashboardTender.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(serviceOfferListRef.current.scrollTop);
    setScrollIsBottom(Math.abs(serviceOfferListRef.current.scrollHeight - serviceOfferListRef.current.scrollTop - serviceOfferListRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    serviceOfferListRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      serviceOfferListRef.current.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  return (
    <div
      ref={serviceOfferListRef}
      onScroll={onScroll}
      className={`flex flex-col space-y-4 pr-2 overflow-y-scroll scrollbar-preset ${
        !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
      }`}
    >
      {offers === null ? (
        <p>{t("loading")}</p>
      ) : offers.length > 0 ? (
        <>
          {offers.map((offer) => {
            return (
              <BigServiceOfferCard
                key={offer.id}
                offer={offer}
                date={offer.date}
                title={offer.tender.title}
                message={offer.message}
                status={offer.status}
                tenderId={offer.tender._id}
                createdAt={offer.createdAt}
                subdomain={offer.tender.subdomain}
                city={offer.tender.city}
              />
            );
          })}
          {!isLastBatch && (
            <div className={`flex items-center justify-center pt-4 pb-8 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
              <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
            </div>
          )}
          {isLastBatch && endMessageShown && (
            <div className={`flex items-center justify-center py-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
              <p className="text-lg">{t("reachedMyServiceOffersEnd")}</p>
            </div>
          )}
        </>
      ) : (
        <p>{t("noMyServiceOffers")}</p>
      )}
    </div>
  );
};

export default BigServiceOffersList;
