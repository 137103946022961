/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useDarkTheme from "../../hooks/useDarkTheme";
import useLogger from "../../hooks/useLogger";

const ConfirmEmail = () => {
  ring2.register();

  const { userId, confirmationCode } = useParams();

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();
  const { showResponse } = useLogger();

  const [message, setMessage] = useState();

  const confirmEmail = async () => {
    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `users/confirm/${userId}/${confirmationCode}`, null, { withCredentials: true })
      .then((response) => {
        setMessage(t("emailConfirmed"));
        localStorage.removeItem("tempEmail");

        showResponse(response);

        setTimeout(() => {
          window.location.assign("/dashboard");
        }, 4000);
      })
      .catch((e) => {
        showResponse(e);

        setTimeout(() => {
          window.location.assign("/");
        }, 2000);
      });
  };

  useEffect(() => {
    confirmEmail();
  }, []);

  return (
    <div className="min-h-screen mx-4 my-24 space-y-4">
      <h2 className="text-3xl font-semibold">{t("emailConfirmation")}</h2>
      <div className="space-y-2">
        <p>{message ? message : <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />}</p>
      </div>
    </div>
  );
};

export default ConfirmEmail;
