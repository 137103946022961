import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../common/language/i18n";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";

import image_2_en_light from "../../images/home/2_1-createTender_en_light.png";
import image_2_fr_light from "../../images/home/2_2-createTender_fr_light.png";
import image_2_en_dark from "../../images/home/2_3-createTender_en_dark.png";
import image_2_fr_dark from "../../images/home/2_4-createTender_fr_dark.png";
import image_3_en_light from "../../images/home/3_1-tenderPage_en_light.png";
import image_3_fr_light from "../../images/home/3_2-tenderPage_fr_light.png";
import image_3_en_dark from "../../images/home/3_3-tenderPage_en_dark.png";
import image_3_fr_dark from "../../images/home/3_4-tenderPage_fr_dark.png";
import image_4_en_light from "../../images/home/4_1-messages_en_light.png";
import image_4_fr_light from "../../images/home/4_2-messages_fr_light.png";
import image_4_en_dark from "../../images/home/4_3-messages_en_dark.png";
import image_4_fr_dark from "../../images/home/4_4-messages_fr_dark.png";
// import image_5_en_light from "../../images/home/5_1-???_en_light.png";
// import image_5_fr_light from "../../images/home/5_2-???_fr_light.png";
// import image_5_en_dark from "../../images/home/5_3-???_en_dark.png";
// import image_5_fr_dark from "../../images/home/5_4-???_fr_dark.png";

const preloadSrcList = [
  image_2_en_light,
  image_2_fr_light,
  image_2_en_dark,
  image_2_fr_dark,
  image_3_en_light,
  image_3_fr_light,
  image_3_en_dark,
  image_3_fr_dark,
  image_4_en_light,
  image_4_fr_light,
  image_4_en_dark,
  image_4_fr_dark
  // image_5_en_light,
  // image_5_fr_light,
  // image_5_en_dark,
  // image_5_fr_dark
];

const FonctionnaliteEntreprise = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  // const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });

  const [hasBeenViewed1, setHasBeenViewed1] = useState(false);
  const [hasBeenViewed2, setHasBeenViewed2] = useState(false);
  const [hasBeenViewed3, setHasBeenViewed3] = useState(false);
  // const [hasBeenViewed4, setHasBeenViewed4] = useState(false);

  useEffect(() => {
    if (inView1 && !hasBeenViewed1) setHasBeenViewed1(true);
  }, [inView1, hasBeenViewed1]);

  useEffect(() => {
    if (inView2 && !hasBeenViewed2) setHasBeenViewed2(true);
  }, [inView2, hasBeenViewed2]);

  useEffect(() => {
    if (inView3 && !hasBeenViewed3) setHasBeenViewed3(true);
  }, [inView3, hasBeenViewed3]);

  // useEffect(() => {
  //   if (inView4 && !hasBeenViewed4) setHasBeenViewed4(true);
  // }, [inView4, hasBeenViewed4]);

  const getImage = (index) => {
    const images = [
      [
        [image_2_en_light, image_2_fr_light],
        [image_2_en_dark, image_2_fr_dark]
      ],
      [
        [image_3_en_light, image_3_fr_light],
        [image_3_en_dark, image_3_fr_dark]
      ],
      [
        [image_4_en_light, image_4_fr_light],
        [image_4_en_dark, image_4_fr_dark]
      ]
      // [
      //   [image_5_en_light, image_5_fr_light],
      //   [image_5_en_dark, image_5_fr_dark]
      // ]
    ];

    const theme = getActualTheme() === "dark" ? 1 : 0;
    const lang = i18n.language === "en" ? 0 : i18n.language === "fr" ? 1 : null;

    return images[index][theme][lang];
  };

  const features = [
    { image: getImage(0), title: t("features1Title"), text: t("features1Text"), ref: ref1, hasBeenViewed: hasBeenViewed1, reverse: false },
    { image: getImage(1), title: t("features2Title"), text: t("features2Text"), ref: ref2, hasBeenViewed: hasBeenViewed2, reverse: true },
    { image: getImage(2), title: t("features3Title"), text: t("features3Text"), ref: ref3, hasBeenViewed: hasBeenViewed3, reverse: false }
    //{ image: getImage(3), title: t("features4Title"), text: t("features4Text"), ref: ref4, hasBeenViewed: hasBeenViewed4, reverse: true },
  ];

  return (
    <section className="text-black dark:text-white">
      <div className="mx-auto max-w-screen-2xl px-4 py-2 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="text-center mb-28 text-xl mx-auto max-w-2xl">
          <h2 className="text-3xl font-bold sm:text-4xl">{t("featuresTitle")}</h2>
          <br />
          <p>{t("featuresDescription")}</p>
        </div>
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className={`grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 ${index > 0 ? "mt-36" : ""}`}
            ref={feature.ref}
            initial="hidden"
            animate={feature.hasBeenViewed ? "visible" : "hidden"}
            variants={sectionVariants}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <div className={`relative h-64 overflow-hidden rounded-lg shadow-md sm:h-80 lg:h-full ${feature.reverse ? "lg:order-last" : ""}`}>
              <img alt={feature.title} src={feature.image} className="w-full h-full" />
            </div>
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">{feature.title}</h2>
              <p className="mt-4 text-gray-600 dark:text-white">{feature.text}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default FonctionnaliteEntreprise;
