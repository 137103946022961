/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useContext } from "react";

import { ImageOverlayContext } from "../../../contexts/ImageOverlayContext";

import { MdClose, MdDownload } from "react-icons/md";

import ImageOverlayIcon from "./elements/ImageOverlayIcon";

const ImageOverlay = ({ src }) => {
  const imageOverlay = useContext(ImageOverlayContext);

  return (
    <div className="absolute flex items-center justify-center w-screen h-screen z-50">
      <div onClick={imageOverlay.clearImage} className="absolute w-full h-full bg-black opacity-75 z-0 animate-fade-in-3/4" />
      <div className="absolute top-0 right-0 -translate-x-2 flex flex-row-reverse p-4 space-x-2 space-x-reverse">
        <ImageOverlayIcon icon={<MdClose />} action={imageOverlay.clearImage} />
        <ImageOverlayIcon icon={<MdDownload />} action={imageOverlay.downloadImage} />
      </div>
      <img src={src} alt="Selected image overlaying entire page" className="h-5/6 rounded-md z-10 animate-scale-in" />
    </div>
  );
};

export default ImageOverlay;
