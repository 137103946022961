/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ring2 } from "ldrs";

import useAuth from "../../../hooks/useAuth";
import useDarkTheme from "../../../hooks/useDarkTheme";
import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import { MdManageSearch, MdOutlineContentPasteGo, MdAddBox, MdOutlineReadMore, MdSubscriptions, MdSettings } from "react-icons/md";

import Card from "../../../components/card/Card";
import ConversationPreview from "../../../components/chat/elements/ConversationPreview";
import DashboardHomeButton from "../../../components/button/DashboardHomeButton";
import DashboardHomeCountdown from "../../../components/card/dashboard/DashboardHomeCountdown";
import TendersListHome from "../../../components/tenders/TendersListHome";

const DashboardHome = () => {
  ring2.register();

  const { t } = useTranslation();

  const { fullName, companies, switchToUser, switchToCompany, isCompany } = useAuth();
  const { getActualTheme } = useDarkTheme();
  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [currentUser, setCurrentUser] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [dashboardData, setDashboardData] = useState({
    // Users
    pendingTenders: "-",
    newServiceOffers: "-",

    // Companies
    newTenders: "-",
    pendingServiceOffers: "-",

    // Both
    activeConversations: "-",
    recentConversations: undefined,
    recentTenders: []
  });

  const getCurrentUser = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user", {
        withCredentials: true
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getUserDashboard = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "users/current-user/dashboard", { withCredentials: true })
      .then((response) => {
        setDashboardData({ ...dashboardData, ...response.data });
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const getCompanyDashboard = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompany.id}/dashboard`, { withCredentials: true })
      .then((response) => {
        setDashboardData({ ...dashboardData, ...response.data });
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    getCurrentUser();

    if (!isCompany()) {
      getUserDashboard();
    }
  }, []);

  useEffect(() => {
    if (selectedCompany.isNull !== null && !selectedCompany.isNull && isFirstLoad) {
      getCompanyDashboard();

      setIsFirstLoad(false);
    }
  }, [selectedCompany]);

  return (
    <div className="flex h-[calc(100svh-3rem)] space-x-4">
      <div className="flex-1/4 flex flex-col space-y-4">
        <div className="flex-1 flex flex-col space-y-3">
          {/* Welcome card */}
          <Card>
            <div className="space-y-2 3xl:space-y-4">
              <div className="text-xl 3xl:text-2xl font-bold">
                <p>{t("dashboardWelcome1")}</p>
                <p className="text-lg 3xl:text-xl italic">{t("dashboardWelcome2", { name: fullName() })}</p>
              </div>
              <div className="3xl:text-lg">
                <p>{t("dashboardLoggedInAs")}</p>
                <p className="text-sm 3xl:text-base font-medium italic">{!isCompany() ? t("userView") : selectedCompany.name}</p>
                {companies() && (
                  <p
                    onClick={() => {
                      isCompany() ? switchToUser() : switchToCompany(currentUser.preferredCompany);

                      window.location.reload();
                    }}
                    className="text-xs 3xl:text-sm text-hoptop-500 cursor-pointer hover:underline"
                  >
                    {isCompany() ? t("dashboardSwitchToUser") : t("dashboardSwitchToPreferredCompany")}
                  </p>
                )}
              </div>
            </div>
          </Card>
          <div className="flex-1 flex space-x-3">
            <div className="flex-1 flex flex-col space-y-3">
              {/* Top left button card */}
              <Card modifiers="padding-xs">
                <DashboardHomeButton
                  icon={!isCompany() ? <MdAddBox /> : <MdManageSearch />}
                  label={!isCompany() ? t("dashboardCreateTender") : t("dashboardAllTenders")}
                  link={!isCompany() ? "/dashboard/tenders/create" : "/dashboard/tenders/browse"}
                />
              </Card>
              {/* Bottom left button card */}
              <Card modifiers="padding-xs">
                <DashboardHomeButton icon={<MdSettings />} label={t("dashboardSettings")} link={"/dashboard/settings"} />
              </Card>
            </div>
            <div className="flex-1 flex flex-col space-y-3">
              {/* Top right button card */}
              <Card modifiers="padding-xs">
                <DashboardHomeButton
                  icon={!isCompany() ? <MdOutlineReadMore /> : <MdOutlineContentPasteGo />}
                  label={!isCompany() ? t("dashboardMyTenders") : t("dashboardMyServiceOffers")}
                  link={!isCompany() ? "/dashboard/tenders" : "/dashboard/service-offers"}
                />
              </Card>
              {/* Bottom right button card */}
              <Card modifiers="padding-xs">
                <DashboardHomeButton icon={<MdSubscriptions />} label={t("dashboardSubscription")} link={"/dashboard/settings/subscription"} />
              </Card>
            </div>
          </div>
        </div>
        {/* Messages card */}
        <Card title={t("dashboardMessages")}>
          {dashboardData.recentConversations ? (
            dashboardData.recentConversations.length > 0 ? (
              <div className="space-y-1">
                {dashboardData.recentConversations.map((preview) => {
                  return <ConversationPreview key={preview._id} preview={preview} link="/dashboard/messages" modifiers="small-home" />;
                })}
              </div>
            ) : (
              <div className={`flex items-center justify-center pt-2`}>
                <p>{t("noConversations")}</p>
              </div>
            )
          ) : (
            <div className={`flex items-center justify-center pt-1`}>
              <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
            </div>
          )}
        </Card>
      </div>
      <div className="flex-3/4 flex flex-col space-y-4">
        <div className="flex-1/5 flex space-x-3">
          {/* Countdown cards */}
          <Card>
            <DashboardHomeCountdown
              value={!isCompany() ? dashboardData.pendingTenders : dashboardData.newTenders}
              label={!isCompany() ? t("dashboardStatsPendingTenders") : t("dashboardStatsNewTendersToday")}
            />
          </Card>
          <Card>
            <DashboardHomeCountdown
              value={!isCompany() ? dashboardData.newServiceOffers : dashboardData.pendingServiceOffers}
              label={!isCompany() ? t("dashboardStatsPendingServiceOffers") : t("dashboardStatsPendingServiceOffers")}
            />
          </Card>
          <Card>
            <DashboardHomeCountdown
              value={!isCompany() ? dashboardData.activeConversations : dashboardData.activeConversations}
              label={!isCompany() ? t("dashboardStatsActiveConversations") : t("dashboardStatsActiveConversations")}
            />
          </Card>
        </div>
        {/* Tenders card */}
        <Card title={!isCompany() ? t("dashboardMyTenders") : t("dashboardAllTenders")} className={"!flex-4/5"}>
          <TendersListHome type={!isCompany() ? "myTenders" : "tenders"} tenders={dashboardData.recentTenders} />
        </Card>
      </div>
    </div>
  );
};

export default DashboardHome;
