import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import { BsExclamation } from "react-icons/bs";

import Popup from "../../popup/Popup";

const UrgentTenderIcon = ({ modifiers = "" }) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`relative w-7 3xl:w-8 h-7 3xl:h-8 ${modifiers.includes("small") && "!w-6 !h-6"} ${modifiers.includes("small-home") && "!w-5 3xl:!w-6 !h-5 3xl:!h-6"}`}>
      <div
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-7 3xl:w-8 h-7 3xl:h-8 bg-gradient-to-tl from-red-800 dark:from-red-900 to-red-500 dark:to-red-600 rounded-md shadow-lg rotate-45 ${
          modifiers.includes("small") && "!w-6 !h-6"
        } ${modifiers.includes("small-home") && "!w-5 3xl:!w-6 !h-5 3xl:!h-6"}`}
      />
      <IconContext.Provider
        value={{
          className: `absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-9 3xl:w-10 h-9 3xl:h-10 text-white pointer-events-none ${
            modifiers.includes("small") && "!w-8 !h-8"
          } ${modifiers.includes("small-home") && "!w-7 3xl:!w-8 !h-7 3xl:!h-8"}`
        }}
      >
        <BsExclamation />
      </IconContext.Provider>
      <Popup
        position="right"
        width="w-max"
        height={`h-7 3xl:h-8`}
        background={modifiers.includes("small-card") ? "bg-neutral-600 dark:bg-neutral-600" : "bg-neutral-350 dark:bg-neutral-650"}
        className={`${!modifiers.includes("small") && "ml-5"}`}
        shown={isHovered}
      >
        <div className="relative px-1 z-10">
          <p className={`text-neutral-50 text-sm 3xl:text-base font-normal`}>{t("isUrgent")}</p>
        </div>
      </Popup>
    </div>
  );
};

export default UrgentTenderIcon;
