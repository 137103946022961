/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardMyTenders = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [myTenders, setMyTenders] = useState(null);

  const getUserTenders = async (date) => {
    let newTenders = [];

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `users/current-user/tenders${date ? `/${date}` : ""}`, {
        withCredentials: true
      })
      .then((response) => {
        if (!date) {
          setMyTenders(response.data.tenders);
        } else {
          newTenders = response.data.tenders;

          setIsLastBatch(response.data.isLastTenders);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newTenders;
  };

  const addNewTendersToList = async (newTenders) => {
    newTenders.forEach((tender) => {
      myTenders.push(tender);
    });
  };

  const getMoreTenders = async (setIsLoading) => {
    if (!isLastBatch) {
      const date = myTenders[myTenders.length - 1].createdAt;

      const newTenders = await getUserTenders(date.substring(0, date.length - 1));
      addNewTendersToList(newTenders);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      getUserTenders();
      setIsFirstLoad(false);
    }
  }, []);

  return (
    <div className="h-[calc(100svh-3rem)]">
      <Card title={t("dashboardMyTenders")} className={"max-h-full"} modifiers="no-overflow">
        <TendersList type="myTenders" tenders={myTenders} getMoreTenders={getMoreTenders} isLastBatch={isLastBatch} />
      </Card>
    </div>
  );
};

export default DashboardMyTenders;
