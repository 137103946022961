/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import settings from "../../data/json/settings.json";

import useJSHasSaveButton from "../../data/hooks/useJSHasSaveButton";
import useSaveButtonFunctions from "../../data/hooks/useSaveButtonFunctions";
import useSettingsFormData from "../../data/hooks/useSettingsFormData";
import { SettingsFormDataContext } from "../../data/contexts/SettingsFormDataContext";

import { MdArrowBack } from "react-icons/md";

import Button from "../button/Button";
import Card from "../card/Card";
import Section from "./elements/Section";

const SettingContent = ({ selectedCategoryId, selectedTabId, selectedSubpageId, prevPage }) => {
  const { t } = useTranslation();

  const contentRef = useRef(null);

  const { formData: originalFormData, setFormData: setOriginalFormData } = useSettingsFormData({ selectedCategoryId, selectedTabId, selectedSubpageId });
  const { formData, setFormData, updateUser } = useSettingsFormData({ selectedCategoryId, selectedTabId, selectedSubpageId });
  const JS_HAS_SAVE_BUTTON = useJSHasSaveButton({ formData, setFormData });
  const SAVE_BUTTON_FUNCTIONS = useSaveButtonFunctions({ formData, setFormData, originalFormData, setOriginalFormData });

  const [tab, setTab] = useState();
  const [subpage, setSubpage] = useState();
  const [hasSaveButton, setHasSaveButton] = useState(false);
  const [sections, setSections] = useState([]);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    // FIXME Along with BigServiceOffersList.jsx, ServiceOffersDisplay.jsx, TendersList.jsx, DashboardHome.jsx, DashboardMessages.jsx & DashboardTender.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(contentRef.current.scrollTop);
    setScrollIsBottom(Math.abs(contentRef.current.scrollHeight - contentRef.current.scrollTop - contentRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    contentRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      contentRef.current.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  useEffect(() => {
    setOriginalFormData({
      ...originalFormData,
      companiesAddressMapInput: formData.companiesAddressMapInput,
      companiesInfoEmail: formData.companiesInfoEmail,
      companiesInfoName: formData.companiesInfoName,
      companiesInfoOperationRange: formData.companiesInfoOperationRange,
      companiesInfoPhone: formData.companiesInfoPhone,
      companiesSelectedDropdown: formData.companiesSelectedDropdown,
      companiesSubdomainsCheckboxes: formData.companiesSubdomainsCheckboxes
    });
  }, [formData?.companiesSelectedDropdown]);

  useEffect(() => {
    if (tab?.jsHasSaveButton && !subpage) {
      setHasSaveButton(JS_HAS_SAVE_BUTTON[tab.jsHasSaveButton]());
    }

    if (subpage?.jsHasSaveButton) {
      setHasSaveButton(JS_HAS_SAVE_BUTTON[subpage.jsHasSaveButton]());
    }
  }, [formData]);

  useEffect(() => {
    if (tab) {
      setHasSaveButton(tab.hasSaveButton);
      setSections(tab.sections);

      if (subpage) {
        setHasSaveButton(subpage.hasSaveButton);
        setSections(subpage.sections);
      }
    }
  }, [tab, subpage]);

  useEffect(() => {
    if (settings) {
      setTab(settings.find((category) => category.id === selectedCategoryId)?.tabs.find((tab) => tab.id === selectedTabId));
    }
  }, [selectedCategoryId, selectedTabId]);

  useEffect(() => {
    if (tab?.subpages) {
      setSubpage(tab.subpages.find((subpage) => subpage.id === selectedSubpageId));
    }
  }, [tab, selectedSubpageId]);

  return (
    <SettingsFormDataContext.Provider value={{ formData: formData, setFormData: setFormData, updateUser: updateUser }}>
      <div
        ref={contentRef}
        className={`flex-3/4 h-[calc(100svh-3rem)] pr-2 overflow-y-scroll scrollbar-preset-thin ${
          !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
        }`}
      >
        <Card className={`${selectedTabId ? "min-h-full" : "h-full"}`} modifiers="flex-1">
          {selectedTabId ? (
            <div className="flex-1 flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                {selectedSubpageId && (
                  <div
                    onClick={() => {
                      window.location.assign(!prevPage ? "/dashboard/settings" : prevPage);
                    }}
                    className="flex items-center justify-center bg-neutral-200 dark:bg-neutral-600 w-8 h-8 ml-1 rounded-full shadow-md cursor-pointer transition-all hover:bg-neutral-300 hover:dark:bg-neutral-550 hover:scale-105 hover:shadow-lg active:bg-neutral-350 active:dark:bg-neutral-650 active:scale-100 active:shadow-md"
                  >
                    <IconContext.Provider value={{ className: "w-1/2 h-1/2" }}>
                      <MdArrowBack />
                    </IconContext.Provider>
                  </div>
                )}
                <h3 className="text-2xl font-bold">{`${t(`settings.categories.${selectedCategoryId}`)} > ${t(`settings.tabs.${selectedTabId}`)}${
                  selectedSubpageId ? ` > ${t(`settings.subpages.${selectedSubpageId}`)}` : ""
                }`}</h3>
              </div>
              <div className="flex-1 space-y-6">
                {sections.map((section) => {
                  return <Section key={section.id} section={section} formData={formData} setFormData={setFormData} />;
                })}
              </div>
              {hasSaveButton && (
                <div className="flex justify-end pt-3">
                  <Button action={SAVE_BUTTON_FUNCTIONS[!selectedSubpageId ? selectedTabId : selectedSubpageId]} label={t("saveChanges")} modifiers="width-md height-lg" />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <p>{t("noSettingTabSelected")}</p>
            </div>
          )}
        </Card>
      </div>
    </SettingsFormDataContext.Provider>
  );
};

export default SettingContent;
