/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardTenders = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [tenders, setTenders] = useState([]);

  const getTenders = async (date) => {
    let newTenders = [];

    await axios
      .patch(
        process.env.REACT_APP_BACKEND_URL + `tenders/browse/${selectedCompany.id}${date ? `/${date}` : ""}`,
        { subdomains: selectedCompany.subdomains },
        {
          withCredentials: true
        }
      )
      .then((response) => {
        if (!date) {
          setTenders(response.data.tenders);
        } else {
          newTenders = response.data.tenders;

          setIsLastBatch(response.data.isLastTenders);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newTenders;
  };

  const addNewTendersToList = async (newTenders) => {
    newTenders.forEach((tender) => {
      tenders.push(tender);
    });
  };

  const getMoreTenders = async (setIsLoading) => {
    if (!isLastBatch) {
      const date = tenders[tenders.length - 1].createdAt;

      const newTenders = await getTenders(date.substring(0, date.length - 1));
      addNewTendersToList(newTenders);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany.id && isFirstLoad) {
      getTenders();
      setIsFirstLoad(false);
    }
  }, [selectedCompany]);

  return (
    <div className="h-[calc(100svh-3rem)]">
      <Card title={t("dashboardAllTenders")} className={"max-h-full"} modifiers="no-overflow">
        <TendersList tenders={tenders} getMoreTenders={getMoreTenders} isLastBatch={isLastBatch} />
      </Card>
    </div>
  );
};

export default DashboardTenders;
